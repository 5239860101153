<template>
  <div class="elevation-reading">
    <div :class="loading ? 'loading' : ''">
      <div v-if="loading" class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
      <i18n-t keypath="location-format" tag="div" class="elevation">
        <template v-slot:value>{{
          loading ? "&nbsp;" : formattedElevation
        }}</template>
        <template v-slot:units>{{ units }}</template>
      </i18n-t>
      <div class="metadata">
        <!-- <div class="watching" v-if="watchId && Number.isFinite(elevation.value)">
          Watching for updates
        </div>-->
        <div id="elevation-source" v-if="supportsElevation && !location.title">
          <div class="source source--phone" v-if="elevation.source === 'phone'">
            <span class="accuracy" v-if="formattedAccuracy">
              ± {{ formattedAccuracy }} {{ units }}&nbsp;
            </span>
            <span>{{ $t("source.phone.description") }}</span>
            <a @click="toggleSource" class="button toggle-source--web">{{
              $t("source.web.toggle")
            }}</a>
          </div>
          <div class="source source--web" v-else>
            <span>{{ $t("source.web.description") }}</span>
            <a @click="toggleSource" class="button toggle-source--phone">{{
              $t("source.phone.toggle")
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { numberFormatted } from '@/helpers';

export default {
  data() {
    return {
      locationFormat: this.$t('location-format'),
    };
  },
  computed: {
    ...mapState(['useFeet', 'elevation', 'loading', 'supportsElevation', 'location', 'watchId']),
    formattedElevation() {
      if (!Number.isFinite(this.elevation.value)) {
        return null;
      }
      return numberFormatted(this.elevation.value, { useFeet: this.useFeet, locale: this.$t.locale });
    },
    formattedAccuracy() {
      if (!Number.isFinite(this.elevation.accuracy)) {
        return null;
      }
      return numberFormatted(this.elevation.accuracy, { useFeet: this.useFeet, locale: this.$t.locale });
    },
    units() {
      if (!Number.isFinite(this.elevation.value) || this.loading) {
        return null;
      }
      return this.$t(this.useFeet ? 'units.feet' : 'units.meters');
    },
  },
  methods: {
    toggleSource() {
      if (!this.supportsElevation) {
        return;
      }
      if (this.elevation.source === 'phone') {
        this.$store.dispatch('fetchElevation');
      } else {
        this.$store.dispatch('watchUserLocation');
      }
    },
  },
};
</script>
